@media (min-width: 992px) {
  .header {
    background-color: #ffffff;
  }
}

@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper.chat_header {
    padding-top: 65px;
  }
}

.pac-container{
    z-index: 9999 !important;
}

.error {
  color: red;
}

.kt-min-height {
  min-height: calc(100vh - 190px);
}

.login-bg {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.login.login-1 .login-content {
  width: 100%;
  max-width: initial;
}
.login.login-1 .login-content .login-form {
  width: 100%;
  max-width: 500px;
}

a.social-btn {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  background: #f3f3f3;
  font-size: 24px;
  display: inline-block;
  margin: 8px 5px;
}
a.social-btn i {
  font-size: 20px;
  line-height: 46px;
  color: #fff;
}

a.social-btn.social-btn-google {
  background: #f54b42;
  color: #fff;
}
a.social-btn.social-btn-facebook {
  background: #3b5999;
  color: #fff;
}
a.social-btn.social-btn-windows {
  background: #4279f5;
  color: #fff;
}
.form-section-title-box {
  width: 100%;
  display: block;
  padding: 15px 0;
  position: relative;
}
.form-section-title-box:before {
  width: 100%;
  display: inline-block;
  height: 1px;
  content: '';
  background: #b3e9db;
  position: absolute;
  top: 50%;
  z-index: 5;
  left: 0;
}
.form-section-title {
  width: auto;
  padding: 5px 15px 5px 0px;
  display: inline-block;
  background: #fff;
  font-weight: 500;
  position: relative;
  z-index: 9;
  font-size: 15px;
  line-height: 22px;
  color: #76d3bb;
}
.sub-section-box {
  width: 100%;
  display: block;
  padding: 10px 15px 0;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #ececec;
}
.form-group label {
  font-weight: 600;
  color: #343434;
}
.radio-inline label.radio {
  font-weight: 400;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current']
  .wizard-label
  .wizard-title {
  color: #3a3a3a;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='done']
  .wizard-label
  .wizard-title {
  color: #b3b3b3;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='done']
  .wizard-label
  .wizard-icon,
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current']
  .wizard-label
  .wizard-icon {
  color: #19d7a5;
}

.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='done']
  .wizard-arrow
  svg
  g
  [fill],
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current']
  .wizard-arrow
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #19d7a5;
}
@media (max-width: 991.98px) {
  .header-mobile {
    background-color: #ffffff;
  }
  .header-profile-process {
    display: none;
  }
}

.custom-select2
  .select2-container--default2021
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 9px 10px 11px;
}
.custom-select2 .select2-container--default .select2-selection--multiple {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
}

.aside {
  width: 230px;
}
@media (min-width: 992px) {
  .aside-fixed .wrapper {
    padding-left: 230px;
  }
  .aside-fixed .applicationwrapper {
    padding-left: 0px;
    padding-top: 65px;
  }
  .aside-enabled.subheader-fixed .subheader {
    left: 230px;
  }
  .aside-enabled .header.header-fixed {
    left: 230px;
  }
  .driverapplication.header.header-fixed {
    left: 0px;
    height: 65px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 97px;
  }
}

/* Loader Css */
.loader-view {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999999;
  height: 100%;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error.error-6 .error-title {
  font-size: 5rem !important;
}

@media screen and (min-width: 992px) {
  .error.error-6 .error-title {
    font-size: 10rem !important;
  }
}

/* Loader Css End */

/* Header dropdown */
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 12px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* margin-top: 8vh; */
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content span:hover {
  background-color: #ddd;
} /*  #C9F7F5 */

/* End Header dropdown */

/* Hide scrollbar line in sidebar */

/* parent div */
#kt_aside_menu_wrapper {
  width: 100%;
  /* height: 100%; */
  height: auto;
  overflow: hidden;
  position: relative;
}
/* child div */
#kt_aside_menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
  overflow-y: scroll;
}

/* End Hide scrollbar line in sidebar */

.dropzoneClass {
  min-height: 200px !important;
}

/* datepicker property */
.calender-class .MuiInput-underline::before,
.calender-class .MuiInput-underline::after {
  border-bottom: 0px !important;
}

.calender-class.MuiInput-underline:active,
.calender-class .MuiInput-underline:focus,
.calender-class .MuiInput-underline:hover {
  border-bottom: 0px !important;
}

.calender-class .MuiInputBase-input {
  height: 2em !important;
  padding: 8px 14px 11px !important;
}

/* End */

/* React Select field css */
.css-yk16xz-control,
.css-yk16xz-control:active,
.css-yk16xz-control:focus {
  background-color: #ebedf3 !important;
  border-color: #ebedf3 !important;
  color: #3f4254 !important;
}
.css-1rhbuit-multiValue {
  background-color: #ffffff !important;
}
/* End */

/* Confirm delete popup css */
.react-confirm-alert-overlay {
  z-index: 999999999 !important;
}
/* End */

/* Todo View model increase z-index */
.fade.todoView-model.modal.show {
  z-index: 99999999;
}
/* End */

@media (min-width: 992px) {
  .header-fixed.aside-minimize.aside-enabled .header.header-fixed {
    left: 70px;
  }
  .header-fixed.aside-minimize.aside-enabled.subheader-fixed .subheader {
    left: 70px;
  }
  .aside-fixed.aside-minimize.aside-minimize-hover .aside {
    width: 230px;
  }
}

.header-mobile-fixed .topbar.dropdown-nav-mobile {
  position: relative;
  padding: 8px;
}
.aside-overlay {
  display: none;
  background: rgba(0, 0, 0, 0.1);
}
.aside-overlay.active {
  display: block;
}
/* Chat message container class chages for showing date */
.showFooter .cs-message__sent-time {
  display: inline !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-18 {
  font-size: 16px !important;
}
.conversations-header {
  padding: 0.2em 0.9em !important;
}

.new-chat-load {
  position: absolute;
  bottom: 45px;
  z-index: 99;
  right: 0px;
}
.new-chat-load button.cs-button.cs-button--border {
  background: #50d7a5;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #3cb186;
}
@media (max-width: 767px) {
  .cs-main-container.chat-main-container {
    overflow: auto;
    flex-wrap: nowrap;
  }
  .chat-main-container.cs-main-container > .cs-sidebar.cs-sidebar--left {
    min-width: 90%;
  }
  .chat-main-container.cs-main-container .cs-chat-container {
    min-width: 90%;
  }
  .cs-conversation-list {
    overflow-anchor: initial;
  }
  .scrollbar-container {
    overflow-anchor: initial !important;
    touch-action: initial !important;
  }

  .new-chat-load {
    display: none;
  }
}

.chat-main-box {
  width: calc(100vw * 2);
  height: 100%;
  display: block;
  overflow: auto;
}
/* .chat-main-box {
    width: 100%; height: 100%;
    display: flex; flex-direction: column;
    overflow: auto;
} */

/* Chat css end */

.checkboxx {
  width: 25px;
}

.menu-option-step {
  cursor: pointer;
}

/* Application popover noted */
.note-popover-modal-class > .popover {
  width: 400px;
}
/* .popover-notes{

}
.popover-notes{

} */
/* Application popover noted End */

.brand {
  height: 108px !important;
}

@media (min-width: 992px) {
  .aside {
    transition: width 0.3s ease;
  }
}
